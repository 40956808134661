<template>
  <v-container class="article py-8">
    <v-row>
      <v-col cols="12">
        <v-card elevation="0">
          <v-img
            :src="article.image"
            :aspect-ratio="16 / 9"
            class="article_image"
          />
        </v-card>
      </v-col>
      <v-col cols="12" class="d-flex">
        <h2 class="text-h4">{{ article.title }}</h2>
        <div v-if="isAdmin" class="article_actions d-flex justify-end pl-4 ml-auto">
          <v-btn
            depressed
            fab
            x-small
            class="mr-2"
            :color="article.is_pinned ? 'accent' : ''"
            @click.prevent.stop="toggleArticlePinned(article)"
          >
            <v-icon>mdi-pin-outline</v-icon>
          </v-btn>
          <v-btn
            depressed
            fab
            x-small
            class="mr-2"
            :to="{ name: 'EditArticle', params: { id: article.id } }"
          >
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
          <v-btn
            depressed
            fab
            x-small
            @click.prevent.stop="deleteArticle(article)"
            color="error"
          >
            <v-icon>mdi-delete-outline</v-icon>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="d-flex article_info">
        <span class="d-flex align-center text-body2">
          <v-icon size="1.25em" class="mr-1">mdi-calendar</v-icon>
          {{ $dayjs(article.published_at).format("DD MMMM YYYY") }}
        </span>
        <span v-if="isAdmin" class="d-flex align-center text-body2 ml-6">
          <v-icon size="1.25em" class="mr-1">mdi-eye-outline</v-icon>
          {{ article.views }}
        </span>
      </v-col>
      <v-col cols="12" class="article_content">
        <div v-html="article.content"></div>
      </v-col>
    </v-row>
    <PopupConfirmDeleteArticle
      v-if="isAdmin"
      v-model="isPopupConfirmDeleteArticleVisible"
      :article="articleToDelete"
      @deleted="$router.push({ name: 'Home' })"
    />
  </v-container>
</template>

<script>
import PopupConfirmDeleteArticle from "@/components/PopupConfirmDeleteArticle.vue";
import { TOGGLE_PAGE_LOADER } from "@/store/app/types";
import { GET_ARTICLE, EDIT_ARTICLE } from "@/store/news/types";

export default {
  name: "Article",
  metaInfo() {
    const title = this.article.title;
    return {
      title,
    };
  },
  created() {
    this.$store.dispatch(TOGGLE_PAGE_LOADER, true);

    this.$store.dispatch(GET_ARTICLE, this.id).then(() => {
      this.$store.dispatch(TOGGLE_PAGE_LOADER, false);
    });
  },
  data: () => ({
    isPopupConfirmDeleteArticleVisible: false,
    articleToDelete: "",
  }),
  props: ["id"],
  components: {
    PopupConfirmDeleteArticle,
  },
  methods: {
    async toggleArticlePinned(article) {
      await this.$store.dispatch(EDIT_ARTICLE, {
        id: article.id,
        is_pinned: !article.is_pinned,
      });

      article.is_pinned = !article.is_pinned;
    },
    deleteArticle($event) {
      this.articleToDelete = $event;
      this.isPopupConfirmDeleteArticleVisible = true;
    },
  },
  computed: {
    article() {
      return this.$store.getters.article;
    },
    articleStatus() {
      return this.$store.getters.articleStatus;
    },
    isAdmin() {
      return this.$store.getters.isAuthenticated;
    },
  },
};
</script>

<style lang="scss" scoped>
.article {
  max-width: 900px;
}
.article_image {
  background: url(../assets/images/logo.svg) center / clamp(100px, 25%, 150px)
      auto no-repeat,
    var(--v-subtle-base);
}
.article_info {
  opacity: 0.7;
}
::v-deep {
  .article_content {
    font-size: 1.25rem;

    hr {
      height: 0.125em;
      border: none;
      background: var(--v-subtle-darken1);
      margin: 1em 0;
    }
    a {
      color: var(--v-accent-base);

      * {
        color: inherit !important;
      }
    }
    img {
      max-width: 100%;
      border-radius: 4px;
    }
    .image {
      margin: 0 auto 1em;
      max-width: 100%;

      &-style-block-align-left {
        margin-left: 0;
        margin-right: auto;
      }
      &-style-block-align-right {
        margin-left: auto;
        margin-right: 0;
      }
      &-style-align-left {
        float: left;
        margin-right: 1em;
      }
      &-style-align-right {
        float: right;
        margin-left: 1em;
      }
    }
    .text-small {
      font-size: 0.85em;
    }
    .text-tiny {
      font-size: 0.7em;
    }
    .text-big {
      font-size: 1.4em;
    }
    .text-huge {
      font-size: 1.7em;
    }
    p {
      margin-bottom: 1em;
    }
    ul,
    ol {
      margin-bottom: 1em;
    }
    li {
      margin-top: 0.5em;

      &::marker {
        font-weight: 600;
        color: var(--v-accent-base);
      }
    }
    .table {
      overflow: auto;
      border: 1px solid var(--v-subtle-darken1);
      border-radius: 4px;
      margin: 1em auto;
      width: 100%;

      table {
        border-collapse: collapse;
        min-width: 100%;
      }
      thead {
        tr {
          border: none;
        }
      }
      tr {
        border-top: 1px solid var(--v-subtle-darken1);
      }
      th,
      td {
        padding: 0.5em;

        &:first-child {
          padding-left: 1em;
        }
        &:last-child {
          padding-right: 1em;
        }
      }
      th {
        text-align: left;
        font-weight: 500;
        padding-top: 1em;
        padding-bottom: 1em;
        background-color: var(--v-subtle-base);
      }
    }
    blockquote {
      position: relative;
      border-left: 0.125em solid var(--v-accent-base);
      padding: 1em 2em;
      margin-bottom: 16px;
      font-style: italic;

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -0.25em;
        height: 2em;
        background-color: #fff;
        width: 0.5em;
        margin-top: -1em;
      }
      &::after {
        content: "";
        position: absolute;
        mask: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48cGF0aCBkPSJNOTYuNCA0MTZoNzcuMWw1MC45LTk2LjZWOTZoLTE2MHYyMjMuNGg3Ny4xTDk2LjQgNDE2em0yMjQgMGg3Ny4xbDUwLTk2LjZWOTZIMjg4LjR2MjIzLjRoODJsLTUwIDk2LjZ6Ii8+PC9zdmc+")
          center / contain;
        background: var(--v-accent-base);
        display: block;
        width: 1em;
        height: 1em;
        top: 50%;
        left: -0.5em;
        margin-top: -0.5em;
      }
    }
  }
}
</style>